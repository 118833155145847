import {Col, Container, Row} from "react-bootstrap";

import {Link} from "react-router-dom";
import React from "react";
import java from "../assets/img/java.png";
import "../Styles/Courses.css";

export const Courses = () => {
    return (
        <section className="courses-body">
            <Container>
                <Link className="link" onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}
                      style={{textDecoration: 'none'}}
                      to={"/JAVA1"}>
                    <div className="javaCourse">
                        <h1><strong>Prezenčný kurz - JAVA pre začiatočníkov</strong></h1><br/>

                        {/*Stredna Sekcia*/}
                        <Row className="gx-0">
                            {/*Lavy stlpec*/}
                            <Col xxl={8} xl={8} md={8} xs={12}>
                                <ul className="text">
                                    <li>Začiatok konania : <strong>1.2.2025 </strong></li>
                                    <li>Miesto konania : <strong>BRATISLAVA</strong></li>
                                    <li>Forma : <strong>PREZENČNÁ</strong></li>
                                    <li>Trvanie : <strong>5 týždňov (celkovo 13 hodín)</strong></li>
                                    <li>Frekvencia : <strong>1 x týždenne (v Nedeľu o 15:00)</strong></li>
                                    <li>Cena : <strong>235 €</strong></li>
                                    <li>Kapacita na jednu triedu : <strong>12 ľudí</strong></li>
                                    <li>Miesto konania : <strong><Link
                                        onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}
                                        style={{textDecoration: 'none'}}
                                        to={"/location"}> HUB Bratislava</Link></strong>
                                    </li>
                                </ul>
                            </Col>

                            {/*Pravy stlpec*/}
                            <Col xxl={4} xl={4} md={4} xs={12}>
                                <img className="java-image" src={java} alt="Header Img"/>
                            </Col>

                        </Row>

                        {/*Footer*/}
                        <Row className="kurzFooter gx-0">
                            <Col xs={12} md={6} xl={6}>
                                <div className="textFooter">
                                    Voľný počet miest 8/12
                                </div>
                            </Col>
                            <Col xs={12} md={6} xl={6}>
                                <div className="textFooterNapravo">
                                    Pre prihlásenie rozkliknite
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Link>
            </Container>
        </section>
    )
}
