import {Col, Container, Row} from "react-bootstrap";
import home from "../assets/img/home-br.png";
import teacher from "../assets/img/teacher-icon.png";
import certificate from "../assets/img/certificate-icon.png";
import CV from "../assets/img/CV-icon.png";
import {useEffect, useState} from "react";
import axios from "axios";
import "../Styles/Home.css"
import "../Styles/Newsletter.css"

export const Home = () => {

    const [post, setPost] = useState();

    useEffect(() => {
        // axios.get('http://localhost:8080/pub/test')
        axios.get('https://shark-app-cttp3.ondigitalocean.app/pub/test')
            .then(response => {
                setPost(response.data);
                console.error(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (
        <div className="Home-body">
            <Container>
                <div className="main-banner">
                    <img className="" src={home} alt="Header Img"/>
                </div>

                <div className="secion-stred">
                    <p>
                    Prezenčná forma štúdia je preukázateľne efektívnejšia ako rôzne online formy, kde chýba osobný kontakt.
                        Preto si zakladáme na individuálnom prístupe k našim študentom a hlavne osobnom kontakte.
                    </p>

                </div>

                <div className="secion-boxes">
                    <Row>
                        <Col xs={12} md={12} lg={4} xl={4}>
                            <div className="box1">
                                <img className="" src={teacher} alt="Header Img"/>
                                <h3>Kvalitný lektor</h3>
                                <p>
                                    Náš lektor je Java programátor s niekoľkoročnou praxou vývoja
                                    backendu webových aplikácií a je pripravený Vám odovzdať nadobudnuté skúsenosti z praxe.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={4} xl={4}>
                            <div className="box2">
                                <img className="" src={CV} alt="Header Img"/>
                                <h3>Vylepšenie životopisu</h3>
                                <p>
                                    Životopis je dôležitá súčasť hľadania novej práce. Pomôžeme Vám aby vyzeral profesionálne a aby obsahoval len to čo je potrebné.
                                </p>
                            </div>
                        </Col>
                        <Col xs={12} md={12} lg={4} xl={4}>
                            <div className="box3">
                                <img className="" src={certificate} alt="Header Img"/>
                                <h3>Získanie certifikátu</h3>
                                <p>
                                    Každý absolvent po úspešnom ukončení kurzu získava certifikát, ktorý deklaruje úroveň jeho
                                    nadobudnutých teoretických vedomostí a praktických zručností.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

        </div>
    )
}
