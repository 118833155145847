import "../Styles/Documentation.css"
import {Container} from "react-bootstrap";

export const SingleDocument = ({
                                   title,
                                   section1,
                                   section2,
                                   section3,
                                   section4,
                                   section5,
                                   description,
                                   article,
                                   imgUrl,
                                   webUrl
                               }: {
    title: any,
    section1: any,
    section2: any,
    section3: any,
    section4: any,
    section5: any,
    description: any,
    article: any,
    imgUrl: any,
    webUrl: any,
}) => {
    return (
        <>
            <Container>
                <div className="singleDocument">

                    <h3>{title === 'undefined' ? '' : title}</h3>
                    <div className="section1">
                        {section1 === 'undefined' ? '' : section1}
                    </div>

                    <div className="section2">
                        {section2 === 'undefined' ? '' : section2}
                    </div>

                    <div className="section2">
                        {section3 === 'undefined' ? '' : section3}
                    </div>
                    <div className="section2">
                        {section4 === 'undefined' ? '' : section4}
                    </div>
                    <div className="section2">
                        {section5 === 'undefined' ? '' : section5}
                    </div>

                </div>
            </Container>
        </>
    )
}
