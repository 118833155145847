import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import kacka from "../assets/img/kackaProfile.png";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import "../Styles/Contact.css";
import instagram from "../assets/img/instagram.png";
import youtubeSocialIcon from "../assets/img/youtube.png";
import gitSocialIcon from "../assets/img/git.png";

export const Contact = () => {
    const formInitialDetails = {
        user_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: ''
    }

    type statusType = {
        message: string,
        success: boolean
    };

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Odoslať');
    const [status, setStatus] = useState<statusType>({message: "", success: false});

    const onFormUpdate = (category: string, value: string) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    useEffect(() => emailjs.init("sZtoDVKcdOx0Q-ksX"), []);

    const form = React.useRef<HTMLFormElement>(null)

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        setButtonText("Odosiela sa...");
        e.preventDefault();
        const serviceId = "service_ulrki3m";
        const templateId = "template_28jxi1j";


        emailjs
            .sendForm(serviceId, templateId, form.current!, {
                publicKey: 'sZtoDVKcdOx0Q-ksX',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setStatus({message: "Správa odoslaná", success: true})
                    setButtonText("Odoslané");
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setStatus({message: "Chyba v odoslaní", success: false})
                },
            );


        console.log(formDetails);
        setFormDetails(formInitialDetails);
    };

    const [capVal, setCapVal] = useState<String | null>(null);


    return (
        <section className="contact" id="connect">
            <Container className="contact-container">
                <Row>
                    <Col className="col-kacka" size={12} md={6}>
                        {/*<img className="animate__animated" src={contactImg} alt="Contact Us"/>*/}
                        <p className="contact-info">
                            MARTYX ACADEMY <br/>
                            martyx s.r.o. <br/>
                            Mikovíniho 1625/13, 83102 Bratislava <br/>
                            +421 951 032 997 <br/>
                        </p>
                        <img className="kacka" src={kacka} alt="Header Img"/>
                    </Col>
                    <Col size={12} md={6}>
                        <div className="animate__animated">
                            {/*<h2>Napíšte nám :)</h2>*/}
                            <form ref={form} onSubmit={handleSubmit}>
                                <Row>

                                    <Col size={12} className="px-1 col-12">
                                        <input type="email"
                                               name="user_email"
                                               value={formDetails.email}
                                               placeholder="Email"
                                               required={true}
                                               onChange={(e) => onFormUpdate('email', e.target.value)}/>
                                    </Col>

                                    <Col size={12} className="px-1 col-12">
                                                <textarea rows={6}
                                                          name="message"
                                                          value={formDetails.message} placeholder="Správa"
                                                          required={true}
                                                          onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                    </Col>


                                </Row>

                                <Row>
                                    <Col size={6} className="px-1">
                                        <ReCAPTCHA sitekey="6LccfOQpAAAAAG2nEsKiYBHz8E9ut808tD0CTeKx"
                                                   onChange={(val) => setCapVal(val)}/>
                                    </Col>
                                    <Col size={6} className="px-1">
                                        <button type="submit" disabled={!capVal}>
                                            <span>{buttonText}</span>
                                        </button>
                                    </Col>
                                </Row>
                                {
                                    status.message &&
                                    <Col>
                                        <p className={!status.success ? "danger" : "success"}>{status.message}</p>
                                    </Col>
                                }
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="social-container">
                <div className="social-icon-contact">
                    <Row>
                        <Col className="social-box1 d-flex justify-content-center" size={4}>
                            <a href="https://www.instagram.com/martin_bugar_" target="_blank"
                               rel="noreferrer"><img src={instagram} alt=""/></a>
                        </Col>
                        <Col className="social-box2 d-flex justify-content-center" size={4}>
                            <a href="https://www.youtube.com/@martyx_code" target="_blank" rel="noreferrer"><img
                                src={youtubeSocialIcon} alt=""/></a>
                        </Col>
                        <Col className="social-box3 d-flex justify-content-center" size={4}>
                            <a href="https://github.com/MartyxAcademy" target="_blank" rel="noreferrer"><img
                                src={gitSocialIcon} alt=""/></a>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}
