import youtube1 from "../assets/img/youtube1.png";
import interviewQ from "../assets/img/interviewQ.png";
import javaForBe from "../assets/img/javaforbegginers.png";
import fun from "../assets/img/FUN.png";
import review from "../assets/img/review.png";
import cloud from "../assets/img/cloud.jpg";
import tutorials from "../assets/img/tutorials.png";
import {Col, Container, Row, Tab} from "react-bootstrap";
import {YoutubeCard} from "./YoutubeCard";
import "../Styles/YoutubeCourses.css";

export const YoutubeCourses = () => {

    const youtube = [
        {
            title: "FULL STACK course",
            description: "Java, React, MySql",
            imgUrl: youtube1,
            webUrl: "https://youtu.be/sHmBGtqJPyA?list=PL6MVIumjKqoPdkmfFq9524smQwB26QiFS",
        },
        {
            title: "Interview Questions",
            description: "JAVA",
            imgUrl: interviewQ,
            webUrl: "https://youtu.be/6lf336mrkKk?list=PL6MVIumjKqoOCk621DinF7i1BAdrxXHsc",
        },
        {
            title: "JAVA FOR BEGINNERS",
            description: "course",
            imgUrl: javaForBe,
            webUrl: "https://youtu.be/32-ZIFrz_64?list=PL6MVIumjKqoO2BZwmyCCrNBTtse42T-9l",
        },
        {
            title: "SOME FUN :D",
            description: "Programmer : movie vs. reality",
            imgUrl: fun,
            webUrl: "https://youtu.be/Nyns-S0a9lc",
        },
        {
            title: "REVIEWS",
            description: "of my coding setup",
            imgUrl: review,
            webUrl: "https://youtu.be/cQrwhwGAwNw",
        },
        {
            title: "JAVA FUNDAMENTALS",
            description: "tutorials",
            imgUrl: tutorials,
            webUrl: "https://youtu.be/iUtkTBjCPuw",
        },
    ];

    return (
        <section className="kurzy-body">
            <Container className="mt-5">
                <Row className="mb-5 section">
                    <Col size={12} sm={12} md={6}>
                        <span className="tagline"> Najnovšie video : </span>
                        <div className="tagline-text">
                            Vytvorenie a Nasadenie Aplikácie na Cloud
                            <ul>
                                <li> BackEnd aplikácie : Spring boot</li>
                                <li> FrontEnd aplikácie : React ts</li>
                                <li> Databáza : PostreSQL </li>
                                <li> Nasadenie na Cloud Digital Ocean</li>
                                <li> Priradenie custom domény</li>
                            </ul>
                        </div>
                    </Col>

                    <Col size={12} sm={12} md={6}>
                        <div className="proj-imgbx">
                            <img src={cloud}/>
                            <div className="proj-txtx">
                                <a href="https://youtu.be/UQqlh4VZUdY" className="webUrl" target="_blank">
                                    <h4>Vytvorenie a Nasadenie Aplikácie na Cloud</h4>
                                    <span>tutorial</span>
                                </a>
                            </div>
                        </div>
                    </Col>

                </Row>
                <Row>
                    <Col size={12}>
                        <span className="tagline"> Ďalšie videá na kanály : </span>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Tab.Content id="slideInUp" className="kurzy-tabs">
                                <Row>
                                    {
                                        youtube.map((youtube, index) => {
                                            return (
                                                <YoutubeCard
                                                    key={index}
                                                    {...youtube}
                                                />
                                            )
                                        })
                                    }
                                </Row>
                            </Tab.Content>
                        </Tab.Container>

                    </Col>
                </Row>

            </Container>

        </section>
    )
}
