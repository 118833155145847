import {Col, Container, Row} from "react-bootstrap";
import "../Styles/Location.css"
import zasadacka from "../assets/img/zasadacka-fix_nova.jpg";


export const Location = () => {


    return (
        <div className="Location-Body">
            <Container className="Location-Main-Container">
                <Row className="gx-0">
                    <div className="location-text">
                        <h3>Miesto konania kurzu</h3>
                        <p>
                            Kurzy prebiehajú v priestrannej zasadacej miestnosti s veľkým LCD televízorom a plátnom s
                            projektorom, na ktorom budeme premietať prezentácie, kód a iné preberané
                            témy. <br/>

                            Počas kurzu je k dispozící občersvenie vo forme kávy, čaju a minerálky pre Vás a pre Vaše
                            notebooky pripojenie na wifi a nabíjačku. Všetko je pripravené tak, aby ste sa počas kurzu cítili
                            príjemne a komfortne.
                        </p>
                    </div>

                </Row>
                <Row className="gx-0">
                    <div className="img-place">
                        <img className="" src={zasadacka} alt=""/>
                    </div>
                </Row>
                <Row className="gx-0 location-adresa">
                    <p>
                        Adresa : HUB Bratislava, Černyševského 10, 851 01 Bratislava - Petržalka <br/>
                    </p>
                </Row>

                <Row className="gx-0">
                    <div className="mapa">
                        <iframe width="720" height="400"
                                src="https://maps.google.com/maps?width=720&amp;height=400&amp;hl=en&amp;q=%C4%8Cerny%C5%A1evsk%C3%A9ho%2010+(HUB%20Bratislava)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        </iframe>
                    </div>
                </Row>


            </Container>
        </div>
    )
}
