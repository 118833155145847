import youtube1 from "../assets/img/youtube1.png";
import interviewQ from "../assets/img/interviewQ.png";

export default [
    {
        //Dokumentacia defaultne zobrazenie
        id: 0,
        title: "Čo je objektovo orientované programovanie (OOP)?",
        section1: "Objektovo orientované programovanie (OOP) je programovacia paradigma, ktorá sa zameriava na používanie" +
            "objektov na reprezentáciu a manipuláciu s údajmi. V OOP sú údaje zapuzdrené v objektoch a objekty sú definované" +
            "svojimi vlastnosťami (atribútmi) a správaním (metódami). OOP poskytuje niekoľko kľúčových konceptov, ktoré " +
            "umožňujú vývojárom písať modulárny, opakovane použiteľný a udržiavateľný kód.",
        section2: "<h4 style='color: brown'>Definícia OOP konceptov v Jave\n</h4>" +
            "Hlavné myšlienky objektovo orientovaného programovania v Jave, koncepty OOP zahŕňajú abstrakciu, zapuzdrenie, " +
            "dedičnosť a polymorfizmus. V zásade nám koncepty Java OOP umožňujú vytvárať metódy a premenné a potom " +
            "ich všetky alebo ich časť znova použiť bez ohrozenia bezpečnosti. Pochopenie konceptov OOP je kľúčom k pochopeniu toho, ako Java funguje.",
        section3: "<h4 style='color: brown'>Java definuje koncepty OOP takto:\n</h4>" +
            "<ul style='margin-left: 20px'> <li><span style='color: #0d6efd'>Abstrakcia.</span> Používanie jednoduchých vecí na znázornenie zložitosti. " +
            "Všetci vieme, ako zapnúť televízor, ale nepotrebujeme vedieť," +
            " ako to funguje, aby sme si to užili. V jazyku Java abstrakcia znamená, že jednoduché veci, ako sú objekty, triedy a premenné, predstavujú " +
            "komplexnejší základný kód a údaje. Je to dôležité, pretože vám to umožní vyhnúť sa viacnásobnému opakovaniu rovnakej práce.\n</li><br>" +

            "<li><span style='color: #0d6efd'>Zapuzdrenie.</span> Prax uchovávania polí v triede ako private a potom poskytovania prístupu k týmto " +
            "poliam prostredníctvom public metód." +
            "Zapuzdrenie je ochranná bariéra, ktorá uchováva dáta a kód v bezpečí v rámci samotnej triedy. Potom môžeme znova použiť objekty, ako sú " +
            "komponenty kódu alebo premenné, bez toho, aby sme umožnili otvorený prístup k dátovému systému v celom systéme.\n</li><br>" +

            "<li><span style='color: #0d6efd'>Dedičnosť.</span> Špeciálna funkcia objektovo orientovaného programovania v jazyku Java, dedičnosť, umožňuje programátorom vytvárať nové triedy," +
            " ktoré zdieľajú niektoré atribúty existujúcich tried. Použitie dedičnosti nám umožňuje stavať na predchádzajúcej práci bez toho, aby sme museli" +
            " znovu vynájsť koleso.\n</li><br>" +

            "<li><span style='color: #0d6efd'>Polymorfizmus.</span> Umožňuje programátorom používať rovnaké slovo v jazyku Java na označenie rôznych vecí v rôznych kontextoch. Jednou z foriem polymorfizmu" +
            " je preťaženie metódy. Vtedy samotný kód naznačuje rôzne významy. Ďalšou formou je prepísanie metódy. Vtedy hodnoty dodaných premenných znamenajú rôzne " +
            "významy. Poďme trochu ďalej.</li><br></ul>",
        section4: "<h4 style='color: brown'>Ako funguje abstrakcia\n</h4>" +
            "Abstrakcia umožňuje programátorom vytvárať užitočné a opakovane použiteľné nástroje. Umožňuje programátorom vytvárať zložité systémy" +
            " ich rozdelením na menšie, lepšie spravovateľné komponenty. Programátor môže napríklad vytvárať niekoľko rôznych typov objektov, ktorými" +
            " môžu byť premenné, funkcie alebo dátové štruktúry. Programátori môžu tiež vytvárať rôzne triedy objektov ako spôsoby definovania objektov.\n<br>" +
            "\n" +
            "Triedou premennej môže byť napríklad adresa. Trieda môže špecifikovať, že každý objekt adresy má mať názov, ulicu, mesto a PSČ. Objektmi môžu byť v" +
            " tomto prípade adresy zamestnancov, adresy zákazníkov alebo adresy dodávateľov. Okrem toho abstrakcia poskytuje mechanizmus na skrytie detailov" +
            " implementácie triedy alebo metódy pred vonkajším svetom a poskytuje klientom zjednodušené rozhranie na interakciu. V Jave môžete dosiahnuť abstrakciu " +
            "prostredníctvom dvoch hlavných mechanizmov: abstraktných tried a rozhraní.\n<br><br>" +
            "<ul style='margin-left: 20px'> <li><span style='color: #0d6efd'>Abstraktné triedy:</span> Abstraktná trieda je trieda, ktorú nemôžete vytvoriť a môžete ju rozšíriť iba o podtriedy. Abstraktné triedy môžu mať abstraktné aj" +
            " neabstraktné metódy. Abstraktné metódy nemajú telo a musíte ich implementovať akoukoľvek podtriedou, ktorá rozširuje abstraktnú triedu. Neabstraktné" +
            " metódy majú telo a môžete ich priamo volať podtriedou.\n</li>" +
            "<li><span style='color: #0d6efd'>Rozhrania:</span> Rozhranie je súbor metód. Môžete ho použiť na definovanie množiny správania, ktoré by mala trieda implementovať. Trieda môže implementovať " +
            "viacero rozhraní a všetky metódy definované v rozhraní musia byť implementované akoukoľvek triedou, ktorá ho implementuje. Ako fungujú koncepty OOP v " +
            "jazyku Java</li></ul>",
        section5: "",
        section6: "",
        description: "<p>copy copy copy <strong>STACK STACK</strong></p>",
        article: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        imgUrl: youtube1,
        webUrl: "https://youtu.be/sHmBGtqJPyA?list=PL6MVIumjKqoPdkmfFq9524smQwB26QiFS",
    },
    {
        //Primitivne datove typy
        id: 1,
        title: "Primitívne dátové typy",
        section1: "V jazyku Java sú primitívne typy údajov preddefinované. Špecifikujú veľkosť a typ akýchkoľvek štandardných hodnôt." +
            " Java má 8 primitívnych dátových typov, a to byte, short, int, long, float, double, char a boolean. Keď je uložený primitívny dátový typ, je" +
            " to zásobník (Stack), ktorému budú hodnoty priradené. Keď sa premenná skopíruje, vytvorí sa ďalšia kópia premennej a zmeny vykonané v skopírovanej premennej" +
            "nebudú odrážať zmeny v pôvodnej premennej.<br><br>" +

            "<strong><u>Celé čísla : Táto skupina zahŕňa byte, short, int, long</u></strong> <br>" +
            "<strong>byte :  </strong>Ide o 1 bajtový (8-bitový) celočíselný dátový typ. Rozsah hodnôt od -128 do 127. Predvolená hodnota je nula. príklad: byte b=10;<br>" +
            "<strong>short : </strong>Ide o 2 bajty (16-bitový) celočíselný dátový typ. Rozsah hodnôt od -32768 do 32767. Predvolená hodnota je nula. príklad: krátky s=11;<br>" +
            "<strong>int :  </strong>Ide o 4 bajtový (32-bitový) celočíselný dátový typ. Rozsah hodnôt od -2147483648 do 2147483647. Predvolená hodnota je nula. príklad: int i=10;<br>" +
            "<strong>long :  </strong>Ide o 8 bajtový (64-bitový) celočíselný dátový typ. Rozsah hodnôt od -9,223,372,036,854,775,808 do 9,223,372,036,854,775,807. Predvolená hodnota nula. príklad: long l=100012<br><br>" +
            "<span style='color: #0d6efd'>public class Demo {</span> <br>" +
            "&nbsp <span style='color: #0d6efd'>public static void main(String[ ] args) {</span><br>" +
            "&nbsp &nbsp <span style='opacity: 0.5'>// byte type</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>byte b = 20;</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>System.out.println(\"b= \" + b);</span><br>" +
            "<br>" +
            "&nbsp &nbsp <span style='opacity: 0.5'>// short type</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>short s = 20;</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>System.out.println(\"s= \" + s);</span><br>" +
            "<br>" +
            "&nbsp &nbsp <span style='opacity: 0.5'>// int type</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>int i = 20;</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>System.out.println(\"i= \" + i);</span><br>" +
            "<br>" +
            "&nbsp &nbsp <span style='opacity: 0.5'>// long type</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>long l = 20;</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>System.out.println(\"l= \" + l);<br>" +
            "&nbsp }<br>" +
            "}</span><br><br>" +

            "<strong><u>Čísla s desatinnou čiarkou: Do tejto skupiny patrí float, double</u></strong><br>" +
            "<strong>float : </strong>Ide o 4 bajtový (32-bitový) dátový typ float. Predvolená hodnota 0,0f. príklad: float ff=10,3f;<br>" +
            "<strong>double: </strong>Ide o 8 bajtový (64-bitový) dátový typ float. Predvolená hodnota 0,0 d. príklad: double db=11,123;<br><br>" +
            "<span style='color: #0d6efd'>public class Demo {</span><br>" +
            "&nbsp <span style='color: #0d6efd'>public static void main(String[ ] args) {</span><br>" +
            "&nbsp &nbsp <span style='opacity: 0.5'>// float type</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>float f = 20.25f;</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'> System.out.println(\"f= \" + f);</span><br>" +
            "<br>" +
            "&nbsp &nbsp <span style='opacity: 0.5'>// double type</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'> double d = 20.25;</span><br>" +
            "&nbsp &nbsp <span style='color: #0d6efd'>System.out.println(\"d= \" + d);" +
            " <span style='color: #0d6efd'><br>&nbsp}<br>" +
            "}</span></span><br><br>" +
            "" +
            "<strong><u>Znaky: Táto skupina predstavuje znaky, ktoré predstavujú symboly, ako sú písmená a čísla.</u></strong>" +
            "<br>" +
            "<strong>char: </strong>Ide o 2 bajty (16-bitov) znak Unicode. Rozsah 0 až 65 535. Príklad: char c=’a’;</strong><br><br>" +
            "" +
            "<span style='color: #0d6efd'>public class Demo {</span><br>" +
            "&nbsp <span style='color: #0d6efd'>public static void main(String[ ] args) {<br><br>" +

            "&nbsp &nbsp char ch = 'S';<br>" +
            "&nbsp &nbsp System.out.println(ch);<br>" +
            "<br>" +
            "&nbsp &nbsp char ch2 = '&';<br>" +
            "&nbsp &nbsp System.out.println(ch2);<br>" +
            "&nbsp }<br>" +
            "}</span>" +
            "<br><br>" +
            "<strong><u>boolean: boolenovský typ sa používa, keď chceme otestovať konkrétnu podmienku počas vykonávania programu. Existujú iba dve hodnoty, ktoré môže mať booleovský typ: true alebo false.</u></strong> <br>" +
            "\n" +
            "Pamätajte, že obe tieto slová boli deklarované ako kľúčové slovo. Boolenovský typ je označený kľúčovým slovom boolean a využíva iba 1 bit úložného priestoru.<br>" +
            "<br>" +
            "<span style='color: #0d6efd'>public class Demo {</span><br>" +
            "&nbsp <span style='color: #0d6efd'>public static void main(String[ ] args) {<br><br>" +

            "&nbsp &nbsp boolean t = true;<br>" +
            "&nbsp &nbsp System.out.println(t);<br>" +
            "<br>" +
            "&nbsp &nbsp boolean f = false;<br>" +
            "&nbsp &nbsp System.out.println(f);<br>" +
            "&nbsp }<br>" +
            "}</span>" +
            "<br><br>",
        section2: "",
        section3: "",
        section4: "",
        section5: "",
        section6: "",
        description: "<p>copy copy copy <strong>STACK STACK</strong></p>",
        article: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        imgUrl: youtube1,
        webUrl: "https://youtu.be/sHmBGtqJPyA?list=PL6MVIumjKqoPdkmfFq9524smQwB26QiFS",
    },
    {
        //Triedy a Objekty v Jave
        id: 2,
        title: "Triedy a Objekty v JAVE",
        section1: "V jazyku Java sú triedy a objekty základnými konceptmi objektovo orientovaného programovania (OOP), " +
            "ktoré sa používajú na reprezentáciu konceptov a entít reálneho sveta. Trieda predstavuje skupinu objektov s " +
            "podobnými vlastnosťami a správaním. Napríklad zvierací typ Pes je trieda, zatiaľ čo konkrétny pes menom Tommy " +
            "je objektom triedy Pes." +
            "<h4 style='color: brown'>Java triedy\n</h4>" +
            "Trieda v jazyku Java je množina objektov, ktoré zdieľajú spoločné charakteristiky/správanie a spoločné vlastnosti/atribúty. " +
            "Je to užívateľsky definovaný plán alebo prototyp, z ktorého sa vytvárajú objekty. Napríklad študent je trieda, zatiaľ čo konkrétny " +
            "študent s názvom Martin je objekt.<br><br>" +
            "<strong>Deklarácia triedy: </strong><br>" +
            "<span style='color: #0d6efd'>class Student {\n</span><br>" +
            " &nbsp &nbsp <span style='opacity: 0.5'>  // dátový člen (tiež premenná inštancie)\n</span><br>" +
            " <span style='color: #0d6efd'> &nbsp &nbsp int id;\n</span><br>" +
            " &nbsp &nbsp  <span style='opacity: 0.5'> // dátový člen (tiež premenná inštancie)\n</span><br>" +
            " <span style='color: #0d6efd'> &nbsp &nbspString name;\n</span><br><br>" +
            " \n" +
            "&nbsp &nbsp <span style='color: #0d6efd'>public static void main(String args[]) {\n</span><br>" +
            " &nbsp &nbsp &nbsp &nbsp <span style='opacity: 0.5'>// vytvorenie objektu Student\n</span><br>" +
            " &nbsp &nbsp &nbsp &nbsp <span style='color: #0d6efd'>Student student1 = new Student();\n</span><br>" +
            " &nbsp &nbsp <span style='color: #0d6efd'>   }\n</span><br>" +
            "<span style='color: #0d6efd'>}</span>",
        section2: "",
        section3: "",
        section4: "",
        section5: "",
        section6: "",
        description: "<p>copy copy copy <strong>STACK STACK</strong></p>",
        article: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        imgUrl: youtube1,
        webUrl: "https://youtu.be/sHmBGtqJPyA?list=PL6MVIumjKqoPdkmfFq9524smQwB26QiFS",
    },
    {
        id: 3,
        title: "Metódy v JAVE",
        section1: "Metóda v jazyku Java alebo Methods of Java je súbor príkazov, ktoré vykonávajú niektoré špecifické úlohy a vrátia výsledok tomu kto tieto metódy zavolá. " +
            "Metóda Java môže vykonávať niektoré špecifické úlohy bez toho, aby čokoľvek vrátila. Java Methods nám umožňuje opätovne použiť kód bez prepisovania kódu. V Jave musí byť každá metóda súčasťou nejakej triedy, ktorá sa líši od jazykov ako C, C++ a Python.\n" +
            "Metóda je ako funkcia, t. j. používa sa na odhalenie správania objektu.\n" +
            "Je to súbor kódov, ktoré vykonávajú určitú úlohu.",
        section2: "<strong>Vo všeobecnosti majú deklarácie metód 6 komponentov:</strong><br>" +
            "1. Modifikátor: Definuje typ prístupu metódy, t. j. odkiaľ k nej možno pristupovať vo vašej aplikácii. V jazyku Java existujú 4 typy" +
            " špecifikátorov prístupu.\n<br>" +
            "<ul style='margin-left: 20px'> <li><span style='color: #0d6efd'>public:</span> Je prístupný vo všetkých triedach vo vašej aplikácii.\n</li>" +
            "<li><span style='color: #0d6efd'>protected:</span> Je prístupný v rámci triedy, v ktorej je definovaný, a v jej podtriedach.\n</li>" +
            "<li><span style='color: #0d6efd'>private:</span> Je prístupný iba v rámci triedy, v ktorej je definovaný.\n</li>" +
            "<li><span style='color: #0d6efd'>default:</span> Je deklarovaný/definovaný bez použitia akéhokoľvek modifikátora. Je prístupný v rámci rovnakej triedy a balíka, v rámci ktorého je" +
            " definovaná jeho trieda.</li>",
        section3: "",
        section4: "",
        section5: "",
        section6: "",
        description: "<p>copy copy copy <strong>STACK STACK</strong></p>",
        article: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        imgUrl: youtube1,
        webUrl: "https://youtu.be/sHmBGtqJPyA?list=PL6MVIumjKqoPdkmfFq9524smQwB26QiFS",
    },
    {
        id: 3,
        title: "Interview Questions",
        section1: "O použití tried a objektov sme hovorili v predošlom článku. V tomto článku rozoberieme, ako definovať nové triedy. Pripomeniem len, že triedy plnia úlohu „typov“ a objekty alebo inštancie tried (termín inštancia je jednoznačnejší, objekt sa často používa aj s iným významom) sú konkrétne „premenné“ týchto typov (napríklad trieda Človek, objekty Miško, Peťko, Maťko). Ešte raz ale upozorňujem, že v Jave premenná neobsahuje priamo objekt, ale iba referenciu naň, premenná a objekt sú dve nezávislé entity, premenná len zabezpečuje prístup k objektu z programu.",
        section2: "",
        section3: "",
        section4: "",
        section5: "",
        section6: "",
        description: "<p>copy copy copy <strong>Interview Interview</strong></p>",
        article: "<p>copy copy copy <strong>Interview Interview</strong></p>",
        imgUrl: interviewQ,
        webUrl: "https://youtu.be/6lf336mrkKk?list=PL6MVIumjKqoOCk621DinF7i1BAdrxXHsc",
    },
]
