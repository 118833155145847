import {Container, Nav, Navbar} from "react-bootstrap";
import logo from '../assets/img/logoCierneSkackou.png';
import instagramIcon from '../assets/img/instagram.png';
import youtubeIcon from '../assets/img/youtube.png';
import gitHubIcon from '../assets/img/git.png';
import '../Styles/Navbar.css';

export const NavBar = () => {

    return (
        <Navbar expand="md" className="scrolled">
            <Container>
                <Navbar.Brand href="/">
                    <img className="logo" src={logo} alt="Logo"/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <Nav.Link href="/"
                            // eslint-disable-next-line no-restricted-globals
                                  className={location?.pathname === '/' ? 'active navbar-link' : 'navbar-link'}>Domov</Nav.Link>
                        <Nav.Link href="/dokumenty"
                            // eslint-disable-next-line no-restricted-globals
                                  className={location.pathname === '/dokumenty' ? 'active navbar-link' : 'navbar-link'}>Dokumentácia</Nav.Link>
                        <Nav.Link href="/blog"
                            // eslint-disable-next-line no-restricted-globals
                                  className={location.pathname === '/blog' ? 'active navbar-link' : 'navbar-link'}>Blog</Nav.Link>
                        <Nav.Link href="/contact"
                            // eslint-disable-next-line no-restricted-globals
                                  className={location.pathname === '/contact' ? 'active navbar-link' : 'navbar-link'}>Kontakt</Nav.Link>
                    </Nav>

            <span className="navbar-text">

              <Nav.Link href="/courses">
                <button>Kurzy</button>
              </Nav.Link>


              <div className="social-icon">
                <a href="https://www.instagram.com/martin_bugar_" target="_blank" rel="noreferrer"><img
                    src={instagramIcon}
                    alt=""/></a>
                <a href="https://www.youtube.com/@martyx_code" target="_blank" rel="noreferrer"><img src={youtubeIcon}
                                                                                                     alt=""/></a>
                <a href="https://github.com/MartyxAcademy" target="_blank" rel="noreferrer"><img src={gitHubIcon} alt=""/></a>
              </div>

            </span>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
