import React from "react";
import "../Styles/ClanokBlogu.css"
import {Container} from "react-bootstrap";


export const KtoryJayzkAkoPrvy = () => {


    return (
        <section className="clanok-blogu-body">
            <Container>
                <div className="clanok">
                    <h1>Ktorý jazyk sa učiť v 2024 ako prvý?</h1>
                    <p>
                        <strong>
                            Z času na čas zahliadnem príspevky na sociálnych sieťach s neustále opakujúcimi sa otázkami,
                            ohľadne zmeny práce s cieľom zamestnať sa v IT sektore.Čo sa musím naučiť, aby som sa
                            zamestnal v IT sektore? Ktorý programovací jazyk musím vedieť aby som sa uplatnil a mal
                            dobrý plat?
                            Stačí, keď si spravím nejaký IT kurz, alebo potrebujem vysokoškolské vzdelanie? Musím vedieť
                            matematiku keď chcem byť programátor?
                        </strong>
                    </p>

                    <p><strong>
                        Keď som začínal s programovaním, mal som na niektoré veci podobné otázky. Samozrejme jedná sa
                        o absolútne legitímnu tému, nakoľko rekvalifikácia a školenie stojí čas, energiu a peniaze a
                        určite nikto z nás nechce tieto prostriedky venovať niečomu čo na konci dňa nebude mať zmysel.
                        Dnes,
                        po takmer 6tich rokoch práce v IT sektore ako Java backend developer, by som sa chcel vyjadriť
                        ku
                        všetkým týmto otázkam a priblížiť moje skúsenosti a postrehy, ktoré som počas tých rokov
                        nadobudol.
                    </strong>
                    </p>

                    <p>
                        <strong>Vysnívaná IT oblasť - </strong> Ak už máte vybranú aspoň oblasť v IT sektore, ktorá Vás
                        zaujíma, alebo
                        ktorej
                        by ste sa chceli do budúcna venovať, okruh znalostí, ktoré budete potrebovať sa výrazne zúži.
                    </p>

                    <p>
                        Napríklad ak chcete byť <strong> vývojár počítačových hier</strong> a zaujíma Vás programovanie logiky,
                        fyziky a
                        celkovo
                        fungovanie počítačovej hry, tak si pravdepodobne budete vyberať medzi jazykmi C# a C++, čo sú v
                        súčasnosti
                        najpoužívanejšie programovacie jazyky v programovaní počítačových hier. C# je využitý napríklad
                        v hernom
                        engine Unity a C++ zase v Unreal engine.
                    </p>

                    <p>
                        Ak Vás zaujíma <strong> vývoj backendu webových aplikácií</strong>, tak ako mňa, čiže programovanie komunikácie s
                        databázou
                        a celkovej logiky webu, v tom prípade máte na výber z viacerých backendových webových
                        technologií. Najpoužívanejšie
                        sú napríklad Java, C#, NodeJs alebo PHP.
                    </p>

                    <p>
                        Na druhú stranu, ak Vás láka<strong> programovanie a z časti aj dizajnovanie toho ako webová stránka</strong>
                        vyzerá,
                        aké sú tam efekty, tlačidlá a celkové užívateľské rozhranie, tak na vývoj frontendu sa v
                        súčasnosti
                        používajú najčastejšie javascriptové nástroje React, Angular alebo Vue, v prípade vývoja
                        hybridných webových
                        aplikácií je tu Flutter, React Native alebo napríklad Ionic.
                    </p>

                    <p>
                        <strong> Dátové analýzy alebo programovanie AI (umelej inteligencie) </strong> je v súčasnosti
                        veľmi
                        populárne a
                        spoločnosti, ktoré disponujú veľkými
                        objemami dát, ktoré je potrebné spracovávať, analyzovať a vyhodnocovať používajú často krát
                        python a jeho príslušné knižnice, ktoré
                        vo veľkej miere urýchľujú a uľahčujú prácu programátora.
                        Python rovnako ako Java patrí k najpoužívanejším jazykom na svete. Python má veľké množstvo
                        knižníc, pomocou
                        ktorých má python rźne možnosti využitia , nielen pre dátovú analýzu.
                    </p>

                    <p>
                        Ďalšou populárnou oblasťou v IT<strong> je vývoj desktopových aplikácií,</strong> či už
                        konkrétne pre
                        windows,
                        IOS ale multiplatformové
                        aplikácie, ktoré fungujú na všetkých typoch operačného systému. Všetko má svoje výhody a
                        nevýhody. Pokiaľ sa rozhodnete
                        pre vývoj aplikácií konkrétne pre windows, tak je asi najlepšou voľbou jazyk C# a príslušné
                        frameworky. Pre IOS to bude
                        zase jazyk swift a v prípade multiplatformových aplikácií je na výber JAVA s knižnicami swing,
                        alebo JavaFX,
                        alebo javascriptová technológia Electron.
                    </p>

                    <p>
                        Rovnako zaujímavá oblasť je<strong> vývoj aplikácií pre mobilné zariadenia.</strong> Ako som sa
                        zmienil
                        vyššie,
                        pri tomto sektore
                        je možností viac. Pokiaľ sa jedná o vývoj natívnych aplikácii pre konkrétny typ zariadenia,
                        napríklad mobilná aplikácia
                        pre android, na výber máme jazyk Kotlin alebo Javu. V prípade mobilných zariadení, ktoré majú
                        operačný systém IOS sa používa
                        jazyk swift, ktorý vychádza z objective C. V súčasnosti je ale populárne robiť hybridné verzie
                        webových aplikácií , ktoré idú
                        dobre aj vo webovom prehliadači a rovnako dobre aj na mobilných zariadeniach všetkých operačných
                        systémov. Za týmto účelom sa
                        používajú technológie ako React Native, Flutter alebo Ionic Framework.
                    </p>

                    <p>
                        Zameraní a sektorov v IT oblasti je oveľa viacej a všetky si tu nebudeme menovať a predpokladá
                        sa, že do budúcna budú
                        oblasti a pracovné pozície v IT sektore pribúdať. Napríklad pred 30timi rokmi neexistovala
                        pracovná pozícia ako vývojár mobilných aplikácií.
                    </p>

                    <p>
                        <strong> Prieskum pracovného trhu -</strong>  Veľmi dôležitou súčasťou výberu vhodného programovacieho
                            jazyka,
                            prípadne IT zamerania
                            je aj prieskum pracovného trhu, aké sú v danej oblasti platové ohodnotenia a aké je
                            uplatnenie.
                            Bolo by zbytočné
                            vynaložiť veľké množsvo úsilia, času a energie a naučiť sa niečo, čo na trhu práce nemá
                            uplatnenie a bude problém
                            sa zamestnať. Hoci vo svete môže byť dopyt po programátoroch určitých technologií, treba
                            brať do
                            úvahy, že pokiaľ
                            máte v pláne pracovať na slovensku, je potrebné preveriť možnosti uplatnenia na našom trhu
                            práce, aby ste na konci
                            dňa neboli sklamaný. Napríklad Flutter je perfektná technológia na vývoj hybridných webových
                            aplikácií, ktoré idú
                            perfektne na mobilných zariadeniach a aj vo webovom prehliadači. Avšak po prieskume
                            pracovného
                            trhu som zistil,
                            že aj napriek tomu že vo svete je Flutter populárny, na slovensku je to s uplatnením horšie.
                            Pokiaľ ale máte v
                            pláne pracovať v zahraničí, či
                            už remote alebo sa tam presťahovať za prácou, tak v tom určite nebude problém, všetko teda
                            závisí od Vašich preferencií.
                    </p>

                    <p>

                        <strong> JAVA na začiatok -</strong> Predsa len v prípade, že si si neviete vybrať ani programovací jazyk
                            a ani
                            oblasť IT,
                            skúste porozmýšľať nad programovacím jazykom Java. Java je komplexný jazyk, v ktorom sa
                            naučíte
                            všetky
                            fundamentálne princípy programovania a čo viac, Java je etalón objektovo orientovaného
                            programovania, čo je
                            na začiatok veľmi prospešné, aby ste si princípy OOP osvojili, nakoľko v súčasnej dobe je
                            OOP
                            veľmi populárne a
                            firmy častokrát vyžadujú od uchádzačov znalosť týchto princípov. Na druhú stranu je Java
                            jeden z
                            najpoužívanejších
                            programovacích jazykov na slovensku a čo sa týka pracovných ponúk, tak patrí medzi
                            programovacie
                            technológie s najlepšćh uplatnením.
                            Čo sa týka platového ohodnotenia, tak java sa nachádza v hornej časti medzi programovacími
                            jazykmi. Takže po zhrnutí, javou urcite nič nepokazíte.
                    </p>

                    <p>
                        Počas kariéry a pracovného života v programovaní je veľká pravdepodobnosť, že sa stretnete s
                        viacerými programovacími technológiamy,
                        jazykmi, frameworkami alebo nástrojmi, takže je v podstate jedno s čím začnete. Java je ale
                        jeden z tých ťažších a komplexnejších jazykov
                        a je to etalón OOP, takže ak sa naučíte Javu a pochopíte princípy OOP, je pravdepodobné že
                        ostatné jazyky pre Vás budú brnkačka.
                    </p>

                    <p>
                        <strong> Je Java mŕtvy jazyk ? - </strong>Každý rok sa nájde skupina ľudí, ktorá tvrdí, že Java je mŕtvy
                            jazyk a
                            že sa neoplatí ju učiť.
                            Pravda ale je, že na Jave je postavená polovica aplikácií sveta, ktoré treba ďalej vyvíjať a
                            udržiavať, čo je práce pre Java
                            developerov. To znamená, že Java tak ľahko mŕtva nebude, pokiaľ všetky tie aplikácie
                            neprepíšeme
                            z Javy do niečoho iného
                            - a to pochybujem, že sa stane.
                    </p>

                    <p>
                        <strong> Veľká komunita je základ -  </strong>To, že je Java jeden z najpoužívanejších jazykov na svete, je
                        dôsledkom hlavne veľkej komunity programátorov,
                        ktorí za ňou stoja. Treba si uvedomiť, že čím je jazyk populárnejší a čím väčšia komunita je za
                        ním, tým viac študijného materialu je k
                        dispozicí a tým je štúdium jednoduchšie. Podľa môjho názoru je programovací jazyk len taký silný
                        aká veľká je komunita, ktorá za ním stojí.
                        Lebo ako to ide v ruka v ruke, žiadna firma nebude začínať vyvíjať aplikáciu v jazyku, kde je
                        problém zohnať dostatok kvalifikovaných ľudí.
                    </p>

                    <p>
                        <strong>Zhrnutie - </strong>Ako už bolo spomenuté, počas kariéry programátora vystriedane množstvo programovacích
                        jazykov, technológií a nástrojov,
                        ktoré sa Vám budú ľahšie učiť, keď už budeme vedieť programovať v nejakom komplexnom
                        programovacom jazyku. Dôvodom, prečo sa stretnete
                        s viacerými technológiami často krát býva, že firma v ktorej ste zamestnaný, má viacero
                        projektov, ktoré majú rozličný technologický stack
                        a môže sa stať, že z dôvodu kapacít Vás presunú z jedneho projektu na druhý, kde budete pracovať
                        s iným jazykom, technológiou alebo nastrojmi.
                        Takéto veci sú úplne normálne a stávajú sa bežne, preto sa toho netreba báť a treba to brať ako
                        príležitosť naučiť sa niečo nové. Preto ak nemáte
                        doposiaľ vybraný programovací jazyk, alebo IT oblasť ktorej by ste sa chceli venovať, vyberte si
                        hocičo a hlavne začnite. Nikdy neviete kam Vás to dovedie :)
                    </p>
                </div>
            </Container>
        </section>
    )
}
