import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect, useState} from 'react'
import ReactPlayer from "react-player";
import "../Styles/JAVA.css";
import java from "../assets/img/java.png";
import lektor from "../assets/img/lektor.png";
import buttonBackImg from "../assets/img/buttonBack.png";
import {Link} from "react-router-dom";
import Table from 'react-bootstrap/Table';
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

export const Java1 = ({url}: { url: any }) => {


    const formInitialDetails = {
        user_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '',
        kurz: 'Java pre začiatočníkov'
    }

    type statusType = {
        message: string,
        success: boolean
    };

    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Odoslať');
    const [status, setStatus] = useState<statusType>({message: "", success: false});

    const onFormUpdate = (category: string, value: string) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
    }

    useEffect(() => emailjs.init("sZtoDVKcdOx0Q-ksX"), []);

    const form = React.useRef<HTMLFormElement>(null)

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        setButtonText("Odosiela sa...");
        e.preventDefault();
        const serviceId = "service_kapemtk";
        const templateId = "template_fxwq46j";

        emailjs
            .sendForm(serviceId, templateId, form.current!, {
                publicKey: 'sZtoDVKcdOx0Q-ksX',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setStatus({message: "Registrácia odoslaná", success: true})
                    setButtonText("Odoslané");
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setStatus({message: "Chyba v odoslaní", success: false})
                },
            );


        console.log(formDetails);
        setFormDetails(formInitialDetails);
    };

    const [capVal, setCapVal] = useState<String | null>(null);

    return (
        <section className="course-inner-body">
            <Container>
                <div className="align">
                    <div className="javaCourse1">

                        {/*Nadpis*/}
                        <Row>
                            <Col xs={1} md={1} xl={1}>
                                <Link style={{textDecoration: 'none'}} to={"/courses"}>
                                    <img className="buttonBack" src={buttonBackImg} alt="Header Img"/>
                                </Link>
                            </Col>
                            <Col xs={11} md={11} xl={11}>
                                <div className="header">
                                    <strong>Prezenčný kurz - JAVA pre začiatočníkov</strong>
                                </div>
                            </Col>


                        </Row>

                        {/*Stredna Sekcia*/}
                        <Row className="strednaSekciaBannerJava">
                            {/*Lavy stlpec*/}
                            <Col xxl={8} xl={8} md={8} xs={12}>
                                <ul className="text">
                                    <li>Začiatok konania : <strong>1.2.2025 </strong></li>
                                    <li>Miesto konania : <strong>BRATISLAVA</strong></li>
                                    <li>Forma : <strong>PREZENČNÁ</strong></li>
                                    <li>Trvanie : <strong>5 týždňov (celkovo 13 hodín)</strong></li>
                                    <li>Frekvencia : <strong>1 x týždenne (v Nedeľu o 15:00)</strong></li>
                                    <li>Cena : <strong>235 €</strong></li>
                                    <li>Kapacita na jednu triedu : <strong>12 ľudí</strong></li>
                                    <li>Miesto konania : <strong><Link
                                        onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}
                                        style={{textDecoration: 'none'}}
                                        to={"/location"}> HUB Bratislava</Link></strong>
                                    </li>
                                </ul>
                            </Col>

                            {/*Pravy stlpec*/}
                            <Col xxl={4} xl={4} md={4} xs={12}>
                                <img className="java-image-java1" src={java} alt="Header Img"/>
                            </Col>

                        </Row>

                        {/*Video*/}
                        <Row className="align-items-center video">
                            <Col xs={12} md={12} xl={12}>
                                <div className='player-wrapper'>
                                    <ReactPlayer
                                        className='react-player'
                                        url={url}
                                        width='100%'
                                        height='100%'
                                        controls={true}
                                    />
                                </div>
                            </Col>
                        </Row>

                        {/*Tabulka rozpis*/}
                        <Row className="align-items-center video">
                            <Col xs={12} md={12} xl={12}>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className="column1" style={{background: "#E5C700FF"}}>#</th>
                                        <th style={{background: "#E5C700FF"}}>1. týždeň - 1h <strong>(ZADARMO)</strong>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Úvod do Kurzu</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Inštalácia prostredia</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>1. program v Jave :)</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col xs={12} md={12} xl={12}>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className="column1" style={{background: "#E5C700FF"}}>#</th>
                                        <th style={{background: "#E5C700FF"}}>2. týždeň - 3h
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Základné dátové typy - Primitívne a Objektové, operátory, komentáre</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Literály - boolean, int, long, byte, double, float, char, null, String</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Vetvenie programu - if, else, switch</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Cykly - for, while, do-while, ternárny operátor</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col xs={12} md={12} xl={12}>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className="column1" style={{background: "#E5C700FF"}}>#</th>
                                        <th style={{background: "#E5C700FF"}}>3. týždeň - 3h
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Vlastné Triedy - OOP, private, public, protected, baliky</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Enkapsulácia, Konštruktor</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Metódy - void, vracanie hodnoty, static, preťaženie</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Operátory - logické, bitové, porovnávacie, instanceof, priorita operátorov
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col xs={12} md={12} xl={12}>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className="column1" style={{background: "#E5C700FF"}}>#</th>
                                        <th style={{background: "#E5C700FF"}}>4. týždeň - 3h</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Polia a trieda ArrayList</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Základy dátových štruktúr - List, Set, Map</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Enum</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Record</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                            <Col xs={12} md={12} xl={12}>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th className="column1" style={{background: "#E5C700FF"}}>#</th>
                                        <th style={{background: "#E5C700FF"}}>5. týždeň - 3h</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Abstrakcia, static, Immutable Objekty</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Interface</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Dedičnosť</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Polymorfizmus</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>

                        </Row>
                        <section className="lektor-section">

                            <Row>
                                <Col xxxl={5} xxl={5} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="img-container">
                                        <div className="img-card">
                                            <img className="lektor" src={lektor} alt="Header Img"/>
                                            <p><strong>Lektor : Ing. Martin Bugár</strong></p>
                                        </div>
                                    </div>

                                </Col>

                                <Col xxxl={7} xxl={7} xl={12} lg={12} md={12} sm={12} xs={12}>
                                    <div className="info-k-registraci">
                                        <strong>Informácie o Lektorovi</strong>
                                    </div>
                                    <div className="lektor-text">
                                        Programovaniu sa venujem od začiatku vysokej školy, čo je už viac ako 10
                                        rokov. Profesionálne programujem už 6.ty rok ako Java backend developer
                                        webových aplikácií. Okrem písania kódu ma baví učiť a odovzdávať nadobudnuté
                                        skúsenosti a vedomosti druhým. Popri práci natáčam kurzové videá na youtube a
                                        neustále
                                        sa učím nové technológie z oblasti programovania. Mám rád osobnú interakciu s
                                        ľuďmi,
                                        preto prezenčná forma vyučovania kurzu je mi najpríjemnšia.
                                    </div>
                                </Col>
                            </Row>
                        </section>
                        <section className="enroll">

                            <Container>
                                <div className="info-k-registraci">
                                    <strong>Informácie ku kurze</strong>
                                </div>

                                <Row className="">
                                    <div className="enroll-text">
                                        1. Cieľom kurzu je osvojiť si základné princípy programovania v JAVE<br/>
                                        2. Počas kurzu budete dostávať zadania na domácu úlohu<br/>
                                        3. Na konci kurzu dostanete certifikát o úspešnom absolvovaní<br/>
                                        4. Adresa konania kurzu :
                                        <Link onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}
                                            to={"/location"}> HUB Bratislava, Černyševského 10, 851 01
                                            Bratislava
                                        </Link>


                                        <br/><br/>

                                        <div className="list">
                                            <strong>Požiadavky a Vstupné Znalosti :</strong>
                                            <ul>
                                                <li>Na absolvovanie tohto kurzu nie sú potrebné žiadne skúsenosti s
                                                    kódovaním a
                                                    programovaním
                                                </li>
                                                <li>Mierne Pokročilá práca s PC a Windows, Linux alebo macOS</li>
                                                <li>Mierne Pokročilá práca s textovým editorom</li>
                                                <li>Na Kurz je potrebné priniesť si vlastný Notebook, na ktorom
                                                    budete
                                                    programovať
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                </Row>
                            </Container>
                        </section>
                        <section className="enroll">
                            <Container>
                                <div className="info-k-registraci">
                                    <strong>Informácie k registrácií</strong>
                                </div>
                                <Row className="align-items-center">
                                    <Col size={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="enroll-text">
                                            Pokiaľ máte záujem zúčastniť sa na kurze, vyplňte registračný
                                            formulár a
                                            odošlite.
                                            Následne Vám zašleme potvrdzujúci mail, že Vaša registrácia prebehla
                                            úspešne.<br/>

                                            1. úvodná hodina je zadarmo, kde si vysvetlíme čo nás čaká a
                                            neminie.
                                            Nainštalujeme si prostredie na programovanie a nakódime si spoločne
                                            prvý
                                            program v Jave.<br/>

                                            Na konci tejto úvodnej hodiny sa rozhodnete, či Vás kurz oslovil a
                                            či chcete
                                            pokračovať.
                                            Ak áno, tak sa zapíšete na ostro :) a až potom uhradíte platbu za
                                            Kurz.
                                        </div>
                                    </Col>

                                    <Col size={12} lg={12} md={12} sm={12} xs={12}>
                                        <div className="animate__animated">
                                            <form ref={form} onSubmit={handleSubmit}>
                                                <Row>
                                                    <Col size={12} sm={6} className="px-1">
                                                        <input type="text" value={formDetails.user_name}
                                                               name="user_name"
                                                               placeholder="Meno"
                                                               required={true}
                                                               onChange={(e) => onFormUpdate('user_name', e.target.value)}/>
                                                    </Col>
                                                    <Col size={12} sm={6} className="px-1">
                                                        <input type="text"
                                                               name="last_name"
                                                               value={formDetails.last_name}
                                                               placeholder="Priezvisko"
                                                               required={true}
                                                               onChange={(e) => onFormUpdate('last_name', e.target.value)}/>
                                                    </Col>
                                                    <Col size={12} sm={6} className="px-1">
                                                        <input type="email"
                                                               name="user_email"
                                                               value={formDetails.email}
                                                               placeholder="Email"
                                                               required={true}
                                                               onChange={(e) => onFormUpdate('email', e.target.value)}/>
                                                    </Col>
                                                    <Col size={12} sm={6} className="px-1">
                                                        <input type="tel"
                                                               name="phone"
                                                               value={formDetails.phone}
                                                               placeholder="Telefónne číslo +421 ..."
                                                               required={true}
                                                               onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                                                    </Col>

                                                    <Col size={12} sm={6} className="px-1">
                                                        <input type="text"
                                                               name="kurz"
                                                               value={formDetails.kurz}
                                                               placeholder="kurz"
                                                               readOnly={true}
                                                               required={true}
                                                               onChange={(e) => onFormUpdate('kurz', e.target.value)}/>
                                                    </Col>


                                                </Row>
                                                <Row>
                                                    <Col size={6} className="px-1">
                                                        <div className="recaptcha">
                                                            <ReCAPTCHA
                                                                theme="light"
                                                                size="normal"
                                                                sitekey="6LccfOQpAAAAAG2nEsKiYBHz8E9ut808tD0CTeKx"
                                                                onChange={(val) => setCapVal(val)}/>
                                                        </div>
                                                    </Col>
                                                    <Col size={6} className="px-1">
                                                        <button type="submit" disabled={!capVal}>
                                                            <span>{buttonText}</span>
                                                        </button>
                                                    </Col>

                                                </Row>

                                                {
                                                    status.message &&
                                                    <Col>
                                                        <p className={!status.success ? "danger" : "success"}>{status.message}</p>
                                                    </Col>
                                                }
                                            </form>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </section>


                        {/*Footer*/}
                        <Row className="kurzFooterJava">
                            <Col>
                                <div className="textFooter">
                                    Tešíme sa na Vás :)
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </Container>
        </section>
    )
}
