import React from 'react';
import './Styles/App.css';
import CookieConsent from "react-cookie-consent";
import {BrowserRouter as Router, Route, Routes,} from "react-router-dom";
import {NavBar} from "./components/NavBar";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Home} from "./Pages/Home";
import {Footer} from "./components/Footer";
import {Documentation} from "./Pages/Documentation";
import {Blog} from "./Pages/Blog";
import {YoutubeCourses} from "./Pages/YoutubeCourses";
import {Courses} from "./Pages/Courses";
import {Java1} from "./Kurzy/Java1";
import {Contact} from "./Pages/Contact";
import {Location} from "./Pages/Location";
import {KtoryJayzkAkoPrvy} from "./ClankyBlogu/KtoryJayzkAkoPrvy";
import {GDPR} from "./Pages/GDPR";


function App(this: any) {

    const GDPR_URL =
        'https://www.martyx-academy.com/gdpr'

    return (
        <>
            <Router>
                <NavBar/>
                <Routes>
                    <Route path="/" element={<Home/>}></Route>
                    <Route path="/dokumenty" element={<Documentation/>}></Route>
                    <Route path="/blog" element={<Blog/>}></Route>
                    <Route path="/martyx" element={<YoutubeCourses/>}></Route>
                    <Route path="/contact" element={<Contact/>}></Route>
                    <Route path="/courses" element={<Courses/>}></Route>
                    <Route path="/gdpr" element={<GDPR/>}></Route>
                    <Route path="/location" element={<Location/>}></Route>
                    <Route path="/JAVA1" element={<Java1 url={'https://youtu.be/V-nyCSCJmwA'}/>}></Route>

                    {/*BLOG*/}
                    <Route path="/ktory-jazyk-ako-prvy" element={<KtoryJayzkAkoPrvy/>}></Route>
                </Routes>
                <CookieConsent
                    location="bottom"
                    cookieName="myAwesomeCookieName2"
                    style={{background: "#2B373B"}}
                    buttonStyle={{
                        color: "#4e503b",
                        fontSize: "13px"
                    }}
                    buttonText="Súhlasim"
                    enableDeclineButton
                    declineButtonText="Nesúhlasim"
                    declineButtonStyle={{
                        color: "#000000",
                        fontSize: "13px"
                    }}
                    expires={1}
                >
                    Za účelom lepšieho fungovania webstránky používame cookies🍪.
                    Viac info nájdeš na{' '}
                    <a href={GDPR_URL} target="_blank" rel="noopener noreferrer">
                        ochrana osobných údajov.
                    </a>
                </CookieConsent>
                <Footer/>
            </Router>
        </>
    );
}

export default App;
