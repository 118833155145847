import React from "react";
import "../Styles/Blog.css"
import programming from "../imagesToArticles/programming.png";
import {Col, Container, Nav, Row} from "react-bootstrap";


export const Blog = () => {


    return (
        <section className="blog-body">
            <Container>
                <div className="blog">
                    <Row className="gx-0 row-cols-lg-3 row-cols-md-2  row-cols-auto">
                        <Col>
                            <div className="card">
                                <Nav.Link href="/ktory-jazyk-ako-prvy">
                                    <img className="card-img-top" src={programming} alt="Card image cap"/>
                                    <div className="card-body">
                                        <h3>S čím začať ??</h3>
                                        <p className="card-text">
                                            Ktorý programovací jazyk sa naučiť ako prvý, aby ste sa dostali do IT
                                            zamestnania?
                                            Ktoré jazyky sú dobré a ktoré sa naopak vôbec neoplatí učiť? Túto
                                            kontroverznú
                                            tému
                                            si komplexnejšie preberieme v tomto článku :)
                                        </p>
                                    </div>
                                </Nav.Link>
                            </div>
                        </Col>


                    </Row>


                </div>
            </Container>
        </section>
    )
}
