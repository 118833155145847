import {Container} from "react-bootstrap";
import '../Styles/GDPR.css';

export const GDPR = () => {
    return (
        <section className="gdpr">
            <div className="gdpr-container">
                <Container>
                    <h3>PREHLÁSENIE PREVÁDZKOVATEĽA O SPRACÚVAVANÍ OSOBNÝCH ÚDAJOV </h3>
                    <div>
                        <p>
                            V tejto sekcií uvádzame informácie o spracúvaní a ochrane osobných údajov v zmysle
                            nariadenia EP
                            a
                            Rady EÚ č. 2016/679 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom
                            pohybe
                            takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov)
                            a
                            zákona SR č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých zákonov
                            (ďalej
                            len: „zákon o ochrane osobných údajov“). <br/><br/>
                        </p>
                        <p>
                            Prevádzkovateľ martyx s.r.o. so sídlom Mikovíniho 1625/13 Bratislava - mestská časť Nové
                            Mesto, 83102, IČO: 54787980 (ďalej len „prevádzkovateľ“), na zaistenie ochrany práv
                            dotknutých
                            osôb prijal primerané technické a organizačné opatrenia, ktoré deklarujú zákonné spracúvanie
                            osobných údajov. Prevádzkovateľ ďalej zaviedol transparentný systém zaznamenávania
                            bezpečnostných incidentov a akýchkoľvek otázok zo strany dotknutej osoby, ako aj iných
                            osôb<br/><br/>
                        </p>
                        <p>
                            V prípade potreby môže dotknutá osoba získať jednotlivé informácie aj telefonicky na
                            +421903943252 alebo e-mailom na info@martyx-academy.com.<br/><br/>
                        </p>
                        <p>
                            <strong><u>1. Prevádzkovateľ</u></strong><br/><br/>
                            <strong>martyx s.r.o.</strong><br/>
                            Mikovíniho 1625/13 Bratislava - mestská časť Nové
                            Mesto, 83102<br/> IČO: 54787980<br/><br/>
                        </p>

                        <p>
                            Vaše údaje spracúvame pre vlastné účely ako Prevádzkovateľ. To znamená, že my stanovujeme
                            účel, pre ktorý Vaše osobné údaje zhromažďujeme, určujeme prostriedky spracúvania a
                            zodpovedáme za ich riadne vykonanie.<br/><br/>
                        </p>

                        <p>
                            <strong><u>2. Sprostredkovatelia</u> </strong><br/><br/>
                        </p>
                        <p>
                            Prevádzkovateľ môže v určitých prípadoch spracúvať osobné údaje dotknutých osôb aj
                            prostredníctvom sprostredkovateľov, ktorí sú poverení spracúvaním osobných údajov v súlade s
                            článkom 28 Nariadenia GDPR.<br/><br/>
                        </p>
                        <p>
                            Sprostredkovatelia spracúvajú osobné údaje dotknutých osôb v mene Prevádzkovateľa.
                            Spracúvanie osobných údajov prostredníctvom sprostredkovateľa nemá negatívny vplyv na výkon
                            a uplatňovanie práv dotknutej osoby. Prevádzkovateľ využíva len sprostredkovateľov
                            poskytujúcich primerané technické, organizačné a ďalšie opatrenia tak, aby spracúvanie
                            spĺňalo požiadavky GDPR a aby bola v plnom rozsahu zabezpečená ochrana práv dotknutej osoby.<br/><br/>
                        </p>
                        <p>
                            Prevádzkovateľ pri spracúvaní osobných údajov dotknutých osôb využíva nasledujúce kategórie
                            sprostredkovateľov:
                            <ul>
                                <li>
                                    dodávateľ zabezpečujúci dodávky technického riešenia, služby webhostingu, údržby a
                                    podpory IT systémov používaných Prevádzkovateľom
                                </li>
                            </ul>
                        </p>
                        <p><br/>
                            Kategórie príjemcov osobných údajov: osoby konajúce na základe poverenia prevádzkovateľa,
                            právny zástupca, audítor, orgány štátnej správy a verejnej moci na výkon kontroly a dozoru.
                        </p>

                        <p><br/><br/>
                            <strong><u>3. Účel spracúvania osobných údajov </u> </strong><br/><br/>
                        </p>
                        <p>
                            Ako prevádzkovateľ výlučne spracúvame osobné údaje, ktoré vieme podložiť legitímnym právnym
                            základom a definovaným účelom:<br/>
                            <ul>
                                <br/>
                                <li>
                                    pri reakcii na dopyt, podnet alebo otázku podanú fyzicky, telefonicky alebo formou
                                    elektronickej/papierovej pošty, za účelom spätnej reakcie smerovanej k vyhoveniu
                                    dotknutej osobe, uplatňujeme právny základ spracúvania čl. 6 ods. 1 písm. f)
                                    Nariadenia GDPR - oprávnený záujem prevádzkovateľa. Ako dotknutá osoba máte právo
                                    kedykoľvek namieta takéto spracúvanie.
                                </li>
                                <br/>
                                <li>
                                    pri prejavení záujmu o naše služby alebo produkty, pri plánovanom nadviazaní
                                    spolupráce, je právny základom spracúvania údajov čl. 6 ods. 1 písm. b) Nariadenia
                                    GDPR – kedy spracúvanie údajov je nevyhnutné na vykonávanie potrebných opatrení pred
                                    uzatvorením zmluvy, čiže počas procesu predzmluvného vzťahu.
                                </li>
                                <br/>
                                <li>
                                    po vzniknutí zmluvného vzťahu medzi prevádzkovateľom a dotknutou osobou, pri
                                    potrebnej kooperatívnej komunikácií, spracúvanie údajov prebieha opäť v zmysle čl. 6
                                    ods. 1 písm. b) Nariadenia GDPR, ktoré je nevyhnutné pre naplnenie zmluvného vzťahu.
                                </li>
                            </ul>
                        </p>

                        <p><br/><br/>
                            <strong><u>4. Zoznam spracúvaných osobných údajov </u> </strong><br/><br/>
                        </p>
                        <p>
                            V závislosti od konkrétneho účelu spracúvania spracúvame rôzne kategórie Vašich osobných
                            údajov:
                        </p>
                        <p><br/>
                            <strong>Registrácia užívateľského konta</strong><br/>
                            – e-mail
                        </p>

                        <p><br/>
                            <strong>Prijímanie a vybavovanie objednávky – zakúpenie školenia </strong><br/>
                            – meno a priezvisko, adresa pobytu alebo iná korešpondenčná adresa pre potreby doručovania
                            zásielky, telefónne číslo, email
                        </p>

                        <p><br/>
                            <strong>Registrácia a odber newslettrov </strong><br/>
                            – e-mail
                        </p>

                        <p><br/>
                            <strong>Kontaktný formulár alebo dopyt inou formou </strong><br/>
                            – meno, e-mail, text správy, vo výnimočných prípadoch telefónne číslo.
                        </p>

                    </div>

                    <p><br/><br/>
                        <strong><u>5. Doba spracúvania a uchovávania Vašich osobných údajov </u> </strong><br/>
                    </p>
                    <p><br/>
                        Vaše osobné údaje, ktoré sme spracovali alebo spracúvame v zmysle čl. 6 ods. 1 písm. b)
                        Nariadenia GDPR – v rámci plnenia záväzkov prevádzkovateľa, ďalej spracúvame za účelom splnenia
                        si našich zákonných povinností na úseku daní a účtovníctva, ktoré nám vyplývajú zo všeobecne
                        záväzných právnych predpisov (napr. uchovávanie jednotlivých účtovných záznamov a fakturácie v
                        zmysle zákona č. 431/2002 Z. z. o účtovníctve v znení neskorších predpisov, pre prípady
                        preukázania splnenia si daňových povinností v zmysle daňových právnych predpisov zákon č.
                        595/2003 Z. z. o dani z príjmov, zákon č. 563/2009 Z. z. o správe daní a pod.), musíme uchovávať
                        po dobu stanovenú príslušnými právnymi predpismi. V každom prípade sa ale riadime zásadou
                        minimalizácie uchovávania osobných údajov v zmysle čl. 5 ods. 1 písm. e) Nariadenia GDPR a preto
                        budú Vaše osobné údaje, ktoré nepodliehajú archivácii podľa osobitných právnych predpisov,
                        vymazané alebo anonymizované.
                    </p>
                    <p><br/>
                        Osobné údaje spracúvané v zmysle čl. 6 ods. 1 písm. a) Nariadenia GDPR – na základe udeleného
                        súhlasu za účelom napr. zasielania aktuálnych marketingových noviniek spracúvame po dobu 3
                        rokov, alebo do jeho odvolania. V prípade končiacej doby spracúvania údajov realizujme kontakt
                        dotknutej osoby, kedy je možné súhlas so spracúvaním osobných údajov na definovaný účel obnoviť
                        a predĺžiť na nasledujúce obdobie. Ak dotknutá osoba súhlas neudelí, alebo nereaguje na vykonaný
                        kontakt, osobné údaje dotknutej osoby už nebudeme ďalej spracúvať - tzn. automaticky údaje
                        vyradíme z evidencie, elektronické údaje technicky vymažeme zo systémov a fyzické skartujeme.
                    </p>
                    <p><br/>
                        Osobné údaje spracúvané v zmysle čl. 6 ods. 1 písm. f) Nariadenia GDPR – na základe oprávneného
                        záujmu, ktoré boli získané pri reakcii na podaný dopyt/podnet alebo otázku po dobu vybavenia
                        dopytu. Ak po vybavení neboli údaje následne postúpené do predzmluvného alebo zmluvného vzťahu,
                        sú bezodkladne vymazané.Ako Prevádzkovateľ zabezpečíme výmaz osobných údajov bez zbytočného
                        odkladu po tom, ako: boli ukončené všetky zmluvné vzťahy medzi Vami a nami ako prevádzkovateľom
                        ; a/alebo
                        <ul>
                            <li>zanikli všetky Vaše záväzky voči prevádzkovateľovi; a/alebo</li>
                            <li>boli vybavené všetky Vaše reklamácie a žiadosti; a/alebo</li>
                            <li>boli vysporiadané všetky ďalšie práva a povinnosti medzi Vami a nami ako
                                prevádzkovateľom; a/alebo
                            </li>
                            <li>boli naplnené všetky účely spracúvania stanovené právnymi predpismi alebo účely
                                spracúvania, na ktoré ste nám dali súhlas, ak spracúvanie prebiehalo na základe súhlasu
                                dotknutej osoby; a/alebo
                            </li>
                            <li>uplynula lehota, na ktorý bol súhlas udelený alebo dotknutá osoba svoj súhlas odvolala;
                                a/alebo
                            </li>
                            <li>bolo vyhovené žiadosti dotknutej osoby o výmaz osobných údajov a bol naplnený niektorý z
                                dôvodov odôvodňujúcich vyhovenie tejto žiadosti; a/alebo
                            </li>
                            <li>nastala rozhodná právna skutočnosť pre skončenie účelu spracúvania a zároveň uplynula aj
                                ochranná retenčná lehota vymedzená s ohľadom na princíp minimalizácie doby uchovávania
                                osobných údajov;
                            </li>
                            <li> a súčasne netrvá oprávnený záujem prevádzkovateľa, zanikli všetky povinnosti stanovené
                                všeobecne záväznými právnymi predpismi, ktoré si vyžadujú uchovávanie osobných údajov
                                dotknutej osoby (najmä na účely archivácie, výkonu daňovej kontroly a pod.), alebo ktoré
                                by bez ich uchovania nebolo možné splniť.
                            </li>
                        </ul>
                        <p><br/>
                            Akékoľvek náhodne získané osobné údaje v žiadnom prípade ďalej systematicky nespracúvame na
                            žiadny nami vymedzený účel. Ak je to možné, informujeme dotknutú osobu, ktorej náhodne
                            získané osobné údaje patria o ich náhodnom získaní a podľa povahy prípadu jej poskytneme
                            potrebnú súčinnosť vedúcu k obnoveniu kontroly nad jej osobnými údajmi. Ihneď po týchto
                            nevyhnutných úkonoch smerujúcich k vyriešeniu situácie, všetky náhodne získané osobné údaje
                            bezodkladne bezpečným spôsobom zlikvidujeme.
                        </p>
                        <p><br/>
                            V prípade záujmu o ďalšie informácie o konkrétnej dobe uchovávania Vašich osobných údajov
                            nás prosím kontaktujte prostredníctvom uvedených kontaktných údajov.
                        </p>

                        <p><br/>
                            <strong><u>6. Zverejnenie údajov </u> </strong><br/><br/>
                        </p>
                        <p>
                            Naša spoločnosť získané údaje v žiadnom prípade nezverejňuje.
                        </p>

                        <p><br/>
                            <strong><u>7. Cezhraničný prenos a profilovanie osobných údajov </u> </strong><br/><br/>
                        </p>
                        <p>
                            Cezhraničný prenos a profilovanie sa nerealizuje.
                        </p>

                        <p><br/>
                            <strong><u> 8. Práva a povinnosti dotknutej osoby</u> </strong><br/><br/>
                        </p>
                        <p>
                            <ul>
                                <li>Zákazník je povinný uviesť iba úplné a pravdivé údaje.</li>
                                <li>Zákazník sa zaväzuje, že v prípade zmeny svoje údaje zaktualizuje a to najneskôr
                                    pred zrealizovaním prvej objednávky nasledujúcej po vzniku zmeny.
                                </li>
                                <li>Zákazník sa zaväzuje, že ak poskytne osobné údaje tretej osoby (meno, priezvisko,
                                    telefónne číslo), robí tak iba s jej súhlasom a dotknutá osoba je oboznámená s
                                    postupmi, právami a povinnosťami, ktoré sú uvedené na tejto stránke.
                                </li>
                                <li>Ako náš klient a dotknutá osoba máte v stanovenom rozsahu právo rozhodovať o
                                    nakladaní s Vašimi osobnými údajmi. Uplatniť uvedené práva si môžete osobne sídle
                                    Prevádzkovateľa alebo telefonicky – písomne (poštou / e-mailom).
                                </li>
                            </ul>

                            <p><br/>
                                <strong>Budeme sa Vám snažiť odpovedať čo najskôr, vždy Vám však odpovieme najneskôr do
                                    30 dní od doručenia Vašej žiadosti. Platné právne predpisy a Nariadenie GDPR, resp.
                                    Zákon Vám zabezpečujú najmä: </strong>
                            </p>

                            <p><br/>
                                <strong><u>Právo na prístup </u></strong>
                                – Máte právo od nás požadovať potvrdenie o tom, či sa spracúvajú Vaše osobné údaje, a
                                pokiaľ tomu tak je, získať kópiu týchto údajov a dodatočné informácie vyplývajúce z čl.
                                15 Nariadenia, resp. § 21 Zákona. V prípade, že o Vás získavame veľké množstvo údajov,
                                môžeme od Vás požadovať, aby ste špecifikovali svoju požiadavku na okruh konkrétnych
                                údajov, ktoré o Vás spracúvame.
                            </p>

                            <p><br/>
                                <strong><u>Právo na opravu </u></strong>
                                – K tomu, aby sme o Vás neustále spracúvali len aktuálne osobné údaje potrebujeme, aby
                                ste nám ich zmenu oznámili čo najskôr ako nastane. V prípade, že o Vás spracúvame
                                nesprávne údaje, máte právo požadovať ich opravu.
                            </p>

                            <p><br/>
                                <strong><u>Právo na výmaz </u></strong>
                                – Ak budú naplnené podmienky článku 14 Nariadenia, resp. § 23 Zákona, môžete požadovať
                                výmaz Vašich osobných údajov. O výmaz preto môžete žiadať napríklad ak ste odvolali Váš
                                súhlas so spracúvaním osobných údajov a neexistuje iný právny základ spracúvania, alebo
                                v prípade, že Vaše osobné údaje spracúvame nezákonne, alebo sa pominul účel, na ktorý
                                sme Vaše osobné údaje spracúvali a nespracúvame ich za iným kompatibilným účelom. Vaše
                                údaje však nevymažeme ak sú potrebné na preukazovanie, uplatňovanie alebo obhajovanie
                                právnych nárokov.
                            </p>

                            <p><br/>
                                <strong><u>Právo na obmedzenie spracúvania </u></strong>
                                – Ak budú naplnené podmienky článku 18 Nariadenia, resp. § 24 Zákona, môžete od nás
                                požadovať, aby sme obmedzili spracúvanie Vašich osobných údajov. O obmedzenie preto
                                môžete žiadať napríklad počas toho, ako namietate správnosť spracúvaných údajov alebo v
                                prípade, že je spracúvanie nezákonné a Vy si neželáte, aby sme údaje vymazali, ale
                                potrebujete, aby ich spracúvanie bolo obmedzené počas toho ako si uplatníte Vaše práva.
                                Vaše údaje naďalej spracúvame ak existujú dôvody na preukazovanie, uplatňovanie alebo
                                obhajovanie právnych nárokov.
                            </p>

                            <p><br/>
                                <strong><u>Právo na prenosnosť </u></strong>
                                – Ak je spracúvanie založené na Vašom súhlase alebo vykonávané za účelom plnenia zmluvy
                                uzatvorenej s Vami a zároveň vykonávané automatizovanými prostriedkami, máte právo od
                                nás obdržať Vaše osobné údaje, ktoré sme od Vás získali v bežne používanom strojovo
                                čitateľnom formáte. Ak o to budete mať záujem a bude to technicky možné, Vaše osobné
                                údaje prenesieme priamo k inému prevádzkovateľovi. Toto právo nebude možné uplatniť na
                                spracúvanie vykonávané z dôvodu plnenia úlohy realizovanej vo verejnom záujme alebo pri
                                výkone verejnej moci.
                            </p>

                            <p><br/>
                                <strong><u>Právo namietať voči spracúvaniu </u></strong>
                                – Ak spracúvame Vaše osobné údaje z dôvodu plnenia úlohy realizovanej vo verejnom záujme
                                alebo pri výkone verejnej moci nám zverenej, alebo pokiaľ je spracúvanie vykonávané na
                                základe našich oprávnených záujmov či oprávnených záujmov tretej strany, máte právo
                                namietať voči takémuto spracúvaniu. Na základe Vašej námietky spracúvanie osobných
                                údajov obmedzíme a pokiaľ nepreukážeme závažné oprávnené dôvody spracúvania, ktoré
                                prevažujú nad Vašimi záujmami, právami a slobodami alebo dôvody na preukazovanie,
                                uplatňovanie alebo obhajovanie právnych nárokov, osobné údaje naďalej spracúvať nebudeme
                                a Vaše osobné údaje zmažeme. Máte právo kedykoľvek namietať proti spracúvaniu osobných
                                údajov na účely priameho marketingu, vrátane profilovania v rozsahu, v akom súvisí s
                                takýmto priamym marketingom. Po vznesení námietky Vaše osobné údaje už na tento účel
                                spracúvať nebudeme.
                            </p>

                            <p><br/>
                                <strong><u>Právo podať sťažnosť </u></strong>
                                – Ak sa domnievate, že spracúvanie Vašich osobných údajov je v rozpore s Nariadením,
                                resp. Zákonom, máte právo podať sťažnosť na jeden z príslušných dozorných orgánov, najmä
                                v členskom štáte Vášho obvyklého pobytu, miesta výkonu práce alebo v miesta údajného
                                porušenia. Pre územie Slovenskej republiky je dozorným orgánom Úrad pre ochranu osobných
                                údajov, so sídlom: Hraničná 4826/12, 820 07 Bratislava, Slovenská republika, web:
                                www.dataprotection.gov.sk, tel.: +421 /2/ 3231 3220.
                            </p>

                            <p><br/>
                                <strong><u>Právo odvolať súhlas </u></strong>
                                – Ak je spracúvanie Vašich osobných údajov založené na súhlase, máte právo tento súhlas
                                kedykoľvek odvolať. Odvolanie súhlasu nemá vplyv na už vykonané spracúvanie. Ak sa
                                kedykoľvek neskôr rozhodnete, že máte záujem opätovne dostávať od nás obchodné a
                                marketingové ponuky o našich produktoch a službách, svoj odvolaný súhlas (respektíve
                                podanú námietku) môžete kedykoľvek znovu udeliť, a to ktoroukoľvek vyššie uvedenou
                                formou kontaktu.
                            </p>

                        </p>

                        <p><br/>
                            <strong><u>9. Kontaktné údaje Úradu a zodpovednej osoby </u> </strong><br/><br/>
                            <p>
                                Úrad na ochranu osobných údajov Slovenskej republiky<br/>
                                Adresa:<br/>
                                Hraničná 12<br/>
                                820 07, Bratislava 27<br/>
                                Slovenská republika <br/>
                                IČO: 36 064 220 <br/><br/>
                            </p>

                            <p>
                                Podateľňa:<br/>
                                pondelok – štvrtok: 8:00 - 15:00 <br/>
                                piatok: 8:00 - 14:00 <br/><br/>
                            </p>

                            <p>
                                Telefonické konzultácie v oblasti ochrany osobných údajov:<br/>
                                Utorok a Štvrtok od 8:00 do 12:00 +421 2 323 132 20 <br/>
                                Sekretariát predsedu úradu +421 2 323 132 11 <br/>
                                Sekretariát úradu +421 2 323 132 14 <br/>
                                Fax: +421 2 323 132 34 <br/>
                                Fax: +421 2 323 132 34 <br/>
                                Hovorca: <br/>
                                mobil: 0910 985 794 <br/>
                                e-mail: hovorca@pdp.gov.sk <br/><br/>
                            </p>
                            <p>
                                E-mail :<br/>
                                a) všeobecne: statny.dozor@pdp.gov.sk <br/>
                                b) pre poskytovanie informácií podľa zákona č. 211/2000 Z. z.: info@pdp.gov.sk <br/>
                                c) webové sídlo: webmaster@pdp.gov.sk <br/>
                                d) na podávanie žiadostí o poskytnutie informácie podľa zákona č. 211/2000 Z. z. o
                                slobodnom prístupe k informáciám využite on-line formulár. <br/>
                                e) emailová adresa, prostredníctvom ktorej Vám bude Úrad poskytovať poradenstvo v
                                oblasti ochrany osobných údajov. Je určená pre deti, mládež, študentov, učiteľov,
                                rodičov, ktorí majú podozrenie, že ich osobné údaje boli zneužité:
                                ochrana@pdp.gov.sk <br/><br/>
                            </p>
                        </p>

                    </p>
                </Container>

            </div>
        </section>
    )
}
