import {Menu, MenuItem, Sidebar, SubMenu} from "react-pro-sidebar";
import React, {useState} from "react";
import "../Styles/Documentation.css"

import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ReceiptRoundedIcon from "@mui/icons-material/ReceiptRounded";
import Parser from "html-react-parser";
import {SingleDocument} from "../components/SingleDocument";
import Articles from "../components/Articles";


export const Documentation = () => {

    const [article, setArticle] = useState(Articles.at(0));
    const [collapsed, setCollapsed] = useState(window.innerWidth < 1000);
    const setSidebarCollapsed = (e: boolean | ((prevState: boolean) => boolean)) => setCollapsed(e);
    const setArticlePage = (e: React.SetStateAction<{
        id: number; title: string; section1: string;
        section2: string; section3: string; section4: string; section5: string; section6: string;
        description: string; article: string; imgUrl: string; webUrl: string;
    } | undefined>) => setArticle(e);


    // @ts-ignore
    return (
        <section className="documents">
            <div style={{display: "flex", minHeight: "100vh"}}>
                <Sidebar collapsed={collapsed}>
                    <Menu>

                        <MenuItem icon={<MenuRoundedIcon/>} onClick={() => setSidebarCollapsed(!collapsed)}> </MenuItem>
                        <MenuItem onClick={() => setArticlePage(Articles.at(1))}>Primitívne dátové typy</MenuItem>
                        <SubMenu label="Java">
                            <MenuItem onClick={() => setArticlePage(Articles.at(2))}> Triedy </MenuItem>
                            <MenuItem onClick={() => setArticlePage(Articles.at(3))}> Metódy </MenuItem>
                        </SubMenu>

                    </Menu>
                </Sidebar>
                <SingleDocument title={Parser("" + article?.title)}
                                section1={Parser("" + article?.section1)}
                                section2={Parser("" + article?.section2)}
                                section3={Parser("" + article?.section3)}
                                section4={Parser("" + article?.section4)}
                                section5={Parser("" + article?.section5)}
                                description={Parser("" + article?.description)}
                                article={Parser("" + article?.article)}
                                imgUrl={article?.imgUrl}
                                webUrl={article?.webUrl}
                />
            </div>


        </section>
    )
}
