import {Col, Row} from "react-bootstrap";
import logo from "../assets/img/logoCierneSkackou.png";

import instagramIcon from '../assets/img/instagram.png';
import youtubeSocialIcon from '../assets/img/youtube.png';
import gitSocialIcon from '../assets/img/git.png';
import {Link} from "react-router-dom";
import "../Styles/Footer.css"


export const Footer = () => {
    return (
        <footer className="footer">
            <Row className="gx-0">
                <Col size={4} sm={4} className="text-center text-sm-end">
                    <div className="social-icon">
                        <div className="social-icon-footer">
                            <a href="https://www.instagram.com/martin_bugar_" target="_blank"
                               rel="noreferrer"><img src={instagramIcon} alt=""/></a>
                            <a href="https://www.youtube.com/@martyx_code" target="_blank" rel="noreferrer"><img
                                src={youtubeSocialIcon} alt=""/></a>
                            <a href="https://github.com/MartyxAcademy" target="_blank" rel="noreferrer"><img
                                src={gitSocialIcon} alt=""/></a>
                        </div>
                    </div>

                </Col>

                <Col size={3} sm={3}>
                    <div className="text-center">
                        <div className="menu">
                            <div>
                                <button onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}><Link
                                    to={"/martyx"}>YouTube</Link></button>
                            </div>
                            <div>
                                <button onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}><Link
                                    to={"/blog"}>Blog</Link></button>
                            </div>
                            <div>
                                <button onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}><Link
                                    to={"/contact"}>Kontakt</Link></button>
                            </div>

                            <div>
                                <button onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}><Link
                                    to={"/location"}>Miesto konania kurzu</Link></button>
                            </div>
                            <div>
                                <button onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}><Link
                                    to={"/gdpr"}>Ochrana osobných údajov</Link></button>
                            </div>

                        </div>
                    </div>
                </Col>

                <Col size={5} sm={5} className="logo">
                    <Link className="link" to="/"
                          onClick={() => setTimeout(() => window.scrollTo({top: 0, behavior: "smooth"}), 100)}>
                        <img src={logo} alt="Logo"/>
                    </Link>

                </Col>
            </Row>

            <Row className="copyright gx-0">
                <p>Copyright 2024. All Rights Reserved</p>
            </Row>

        </footer>
    )
}
